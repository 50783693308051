.erase{
    opacity: 0;
    transform: scale(0.8);
}

@media (prefers-reduced-motion: no-preference) {
  .erase-animation {
    transition: opacity 1.5s ease, transform 1.5s ease;
  }
}

.erase-animation-transition {
  opacity: 1;
  transform: none;
}

.bg-proposito-1{
  background: url("../assets/about7.2.jpg") no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.bg-valores{
  background: url("../assets/about4.2.jpg") no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
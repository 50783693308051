.inner{
    overflow: hidden !important;
    
}
.card .inner img{
    transition:  all 1.5s ease !important;
}
.card:hover .inner img{
    transform: scale(1.2) !important;
}

.bg-indigo{
    background-color: #571f55 !important;
}
.bg-pink{
    background-color: #e71f64 !important;
}
.bg-orange{
    background-color: #ed6f1a !important;
}
.btn-primary{
    background-color: #529fd8 !important
}
.btn-success{
    background-color: #6fb857 !important
}
.bg-aqua{
    background-color: rgb(0,128,128) !important;
}
.bg-red-alimentacion{
    background: url(../assets/icon-food-1.png) !important;
    background-size: cover !important;
    top:63% !important;
}

.bg-red-salud{
    background: url(../assets/icon-health-1.1.png) !important;
    background-size: cover !important;
    top:63% !important;
}

.bg-red-vivienda{
    background: url(../assets/icon-home-1.png) !important;
    background-size: cover !important;
    top:63% !important;
}
.bg-red-ambiente{
    background: url(../assets/icon-ambiente-1.png) !important;
    background-size: cover !important;
    top:63% !important;
}
.bg-red-energia{
    background: url(../assets/icon-energy-1.png) !important;
    background-size: cover !important;
    top:63% !important;
}
.bg-red-arte{
    background: url(../assets/icon-art-1.png) !important;
    background-size: cover !important;
    top:63% !important;
}
.bg-red-educacion{
    background: url(../assets/icon-education-1.png) !important;
    background-size: cover !important;
    top:63% !important;
}

.bg-red-innovacion{
    background: url(../assets/icon-innovation-1.png) !important;
    background-size: cover !important;
    top:63% !important;
}
.bg-red-seguridad{
    background: url(../assets/icon-segurity-1.png) !important;
    background-size: cover !important;
    top:63% !important;
}

.bg-red-inclusion{
    background: url(../assets/icon-inclusion-1.png) !important;
    background-size: cover !important;
    top:63% !important;
}
.bg-red-sport{
    background: url(../assets/icon-sport-1.png) !important;
    background-size: cover !important;
    top:63% !important;
}
.ImageCardBg{
    opacity: 0.3;
}
.inner{
    overflow: hidden !important;
    
}
.card .inner img{
    transition:  all 1.5s ease !important;
}
.card:hover .inner img{
    transform: scale(1.5) !important;
}
.width-100{
    width: 100%;
}
.redes-bg{
    background: url(../assets/redes.jpg) no-repeat center center fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.height-a{
    height: 20vh !important;
}
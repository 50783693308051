@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,700');

body {
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  }

.button-bg{
    background-color: #571f55 !important;
}

.main-banner-image{
    background-image: url("../assets/navbar2.jpg");
    background-size: cover !important;
    height: 100vh !important;    
}
.about-banner-image{
    background: url("../assets/about2.2.jpg") no-repeat center center fixed; 
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    height: 40vh !important;    
}
.about-banner-image{
    background: url("../assets/impulsando-redes-2.1.jpg") no-repeat center center fixed; 
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    height: 60vh !important;    
}
.contactanos-banner-image{
    background: url("../assets/about9.jpg") no-repeat center center fixed; 
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    
}